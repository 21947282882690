import React from "react";
import './ProductDescription.css';

const ProductDescription = ({ props }) => {

   

    let paragraphContent;

    switch (window.location.pathname) {
        case '/about-us':
            paragraphContent = (
                <>
                    <p>
                        <b>
                        Savings Storm is linked to an exclusive discounts page called My
                        Deals.
                        </b>
                    </p>
                    <br />
                    <p>
                    To access your membership, go to <b style={{wordBreak: 'break-word'}}>www.vip.enjoymydeals.com</b>&nbsp;
                    and enter your Member ID. You will then be able to register.
                    Enjoy tons of special offers and unique discounts.
                    </p>
                    <br />
                    <p>
                    Access your exclusive discounts from your <b> mobile phone by
                    downloading our app, available for Apple or Android </b> and get
                    notified when special offers and discounts become available at
                    your favorite stores.
                    </p>
                </>
            ); 
            break;

        default:
            paragraphContent = null;

    }

    return (
        <section className="ProductDescription__main-container">
            <div className="ProductDescription__explanation-container">
                <div className="ProductDescription__title-container">
                    <h1>{props.title}</h1>
                </div>
                <div className="ProductDescription__explanation-container">
                   {paragraphContent}
                </div>
            </div>

            <div className="ProductDescription__img-container">
                <div className="ProductDescription__img-inner-container">
                    <img src={props.img} alt={props.title} className="ProductDescription__img" />
                </div>
            </div>
        </section>
    );
};

export default ProductDescription;