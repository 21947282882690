import React from "react";

import './PrivacyTerms.css'
import ContactCard from '../../../shared/components/ContactCard/ContactCard';

const PrivacyTerms = props => {

    return (
        <>
            <section className="PrivacyTerms__main-section-container">
                <div>
                    <h1 className="text-black text-center PrivacyTerms__main-title"> Privacy Policy </h1>
                </div>
                <div className="PrivacyTerms__description">
                    <p>
                        <b>Savings Storm Website and Mobile Application Privacy Policy:</b>
                        The following describes the privacy policy (“Privacy Policy”) applicable to the services available through the savingsstorms.com website and any other
                        online communications and interfaces (collectively, the “Site”) from Savings Storm. By visiting the Site, you are consenting to the collection, use, disclosure,
                        storage, transfer and processing of your personal data described in this Privacy Policy. On occasion Savings Storm may revise this Privacy Policy to reflect
                        changes in the law, our Personal Data collection and use practices, the features of our Site, or advances in technology.
                    </p>
                    <p></p>
                    <p>
                        If we make any material changes, we will notify you either via email (sent to the e-mail address specified in your account) or by means of a prominent
                        notice on this Site. You should also check back frequently to ensure you understand our then-current Privacy Policy. The Site is meant for use by United
                        States residents only; others may not use the Site at this time.
                    </p>
                    <p></p>
                    <p className="PrivacyTerms__title">1. Savings Storm Commitment to Your Privacy</p>
                    <p>
                        Savings Storm is committed to protecting your privacy through commercially reasonable means, as outlined herein. We understand that your personal
                        information is important to you. We value your trust in us and will work hard to earn your confidence so that you can use our services and recommend
                        us to your friends and family. We welcome your comments about our Privacy Policy and hope you will contact us if you have any concerns.
                    </p>
                    <p></p>
                    <p className="PrivacyTerms__title">2. Information Collected by Savings Storm</p>
                    <p>
                        We collect personally identifiable information (“Personal Data”) about you that you provide to us while using the Site. Personal Data includes information
                        that can identify you as a specific individual, such as your name, address, email address, phone number, social security number, date of birth and credit
                        card number. We also collect non-personally identifiable information, including your IP address, search preferences related to specific searches, location,
                        and interactions with our Site. When we link non-personally identifiable information with Personal Data, we will treat at as Personal Data under this
                        Privacy Policy.
                    </p>
                    <p></p>
                    <p>
                        Please realize that we can only control the privacy practices of our own company. Our Site may at times include links to other web sites and online
                        services whose privacy practices may differ from those of Savings Storm. These services will authenticate your identity and provide you the option to share
                        certain personal information with us such as your name and email address to pre-populate our sign up form. Such services may give you the option to
                        post information about your activities on this Site to your profile page to share with others within your network. If you submit personal information to or
                        through any of those web sites or services, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy
                        of any web site you visit or online services you use.
                    </p>
                    <p></p>
                    <p>
                        If you use the Site to obtain advice for or about another person, we may collect the name of, and information about, that individual. You must make sure
                        that you have the right to provide such information. By providing any Personal Data of another person, you represent that you have all necessary
                        authority and/or have obtained all necessary consents from such person to enable us to collect, use and disclose such Personal Data as set forth in this
                        Privacy Policy.
                    </p>
                    <p></p>
                    <p className="PrivacyTerms__title">3. Protecting Your Personal Data</p>
                    <p>
                        To prevent unauthorized access, maintain data accuracy, and ensure correct use of Personal Data, we employ reasonable and current technical,
                        administrative and physical safeguards. To protect credit card transactions while in transit, Savings Storm currently uses commercially reasonable
                        encryption.
                    </p>
                    <p></p>
                    <p className="PrivacyTerms__title">4. How Savings Storm Uses the Information It Collects</p>
                    <p>
                        We use Personal Data to provide you with the products and services from our Site, to build features that will make the services available on the Site
                        easier to use, and to contact you about these services. This includes faster purchase requests, better customer support and timely notice of new services
                        and special offers and more relevant content.
                    </p>
                    <p></p>
                    <p>
                        Savings Storm uses the information you provide to perform the services you purchase, including for example, to monitor your credit, search for information
                        about you online, obtain your credit reports for you, freeze or unfreeze access to your credit information, help you update third parties in the event of a
                        lost or stolen wallet, reduce the junk mail you receive and perform a criminal background check for you.
                    </p>
                    <p></p>
                    <p>
                        From time to time, Savings Storm may contact you to request feedback on your experience using the Site, to assist us in improving the Site, or to offer
                        special savings or promotions to you, as a Site user. If you would prefer not to receive notices of special savings or promotions, you may simply opt-out
                        from receiving them by using the hyperlink provided in these notices.
                    </p>
                    <p></p>
                    <p className="PrivacyTerms__title">5. Sharing Your Personal Data</p>
                    <p>
                        Savings Storm does not share your Personal Data, except as disclosed in this Privacy Policy or as otherwise authorized by you. Savings Storm may share your
                        Personal Data with its subsidiaries and with third parties as described in the categories below:
                    </p>
                    <p></p>
                    <p>
                        <b>External Service Providers:</b> When you use the Savings Storm service to consult with a participating vendor or to take advantage of any of our other
                        products and services, we may provide Personal Data to the vendoror other involved third-party. Please refer to the websites of third party suppliers for
                        their Privacy Policies and other important information.
                    </p>
                    <p></p>
                    <p>
                        <b>Savings Storm Third Party Partners:</b> In order to provide you with certain services, we may share your Personal Data with third party partners, or require
                        that you transact directly with a third party partner. Third party partners consist of companies with which Savings Storm has contracted in order to provide
                        you with services or products featured on our Site, such as:
                    </p>
                    <p></p>
                    <ul className="PrivayTerms__list">
                        <li>Trust Guard Dispute Resolution</li>
                        <li>AIG Insurance</li>
                    </ul>
                    <p></p>
                    <p>
                        Please refer to the websites of third party suppliers for their Privacy Policies and other information on the suppliers. We may revise this list from time to
                        time if we decide to offer additional services through these or other third-party partners.
                    </p>
                    <p></p>
                    <p>
                        <b>Savings Storm Agents:</b>Many of the operations we perform require us to hire other companies to help us with these tasks. For example, Savings Storm may
                        hire companies to help us handle mail and e-mail, analyze the data we collect, market our products and services, handle credit card transactions and
                        provide customer service. The companies we employ to help us with these tasks have access to Personal Data to perform their functions, but may not
                        use it for other purposes. identity acts in good faith to require all agents to whom Personal Data is disclosed to enter into confidentiality agreements and
                        to agree to act in a manner consistent with the relevant principles articulated in this Policy.

                    </p>
                    <p></p>
                    <p>
                        <b>Advertisements, Marketing Material and Other Offers:</b> Unless you tell us that you do not want to receive these offers and subject to applicable law,
                        Savings Storm or those companies that we have a commercial relationship with, may occasionally send advertisements, marketing material or other offers
                        that we think might be of interest to you. If you do not want to receive these advertisements, marketing material or other offers, please send an email to&nbsp;
                        <b>help@savingsstorms.com.</b>
                    </p>
                    <p></p>
                    <p>
                        Where required by law, we will seek your consent prior to sending you any marketing materials. To the extent allowed by law, the provision of your email
                        and phone number to us constitutes your prior express written consent, and electronic signature, authorizing us to contact you at that email address and
                        phone number, including through the possible use of an automatic telephone dialing system or artificial or prerecorded voice, live calls and text
                        messages, for both promotional and informational reasons.
                    </p>
                    <p></p>
                    <p>
                        You agree that this consent may be assigned by us to third parties and affiliates. You are not required to provide such consent in order to make a
                        purchase as you can always contact us directly to arrange an alternate purchase method. By providing your telephone number to us, you certify that this
                        is your own number that you own, and not a line owned or used by another, and that you will immediately notify us if your number changes or is
                        reassigned. You agree to indemnify us if this is not the case and if the future owner or user of the number makes a claim against us for contact at that
                        number. You may opt out of such contact at any time and through any of the reasonable methods outlined herein. A copy of Savings Storm written No-
                        Not-Call Policy will be provided upon request.
                    </p>
                    <p></p>
                    <p>
                        <b>Future Business Transfers:</b> Our business is constantly changing. As part of that process, Savings Storm may sell or buy other companies, and Personal Data
                        may be transferred as part of these transactions. It is also possible that Savings Storm or substantially all of its assets, may be acquired by another
                        company, whether by merger, sale of assets or otherwise, and Personal Data may be transferred as part of such a transaction. In such cases, the
                        acquiring company would be required to honor the privacy promises in this Privacy Policy or obtain your consent to any material changes to how your
                        Personal Data will be handled.
                    </p>
                    <p></p>
                    <p>
                        <b>Compliance with Law and Other Disclosures:</b> Savings Storm reserves the right to release Personal Data in order to comply with applicable law and when
                        we believe that disclosure is necessary to comply with a judicial proceeding, court order, or legal process served on us. We may also release Personal
                        Data to enforce or apply the terms and conditions applicable to our products and services, protect us or others against fraudulent or inappropriate
                        activities, or otherwise protect the rights, property or safety of identity our affiliated companies, our customers, or others.
                    </p>
                    <p></p>
                    <p className="PrivacyTerms__title">6. Savings Storm Policy Concerning Cookies and Other Tracking Technologies</p>
                    <p>
                        Cookies and similar tracking technologies, such as beacons, scripts, and tags, are small bits of code, usually stored on a user’s computer hard drive or
                        device, which enable a website to “personalize” itself for each user by remembering information about the user’s visit to the website.
                    </p>
                    <p></p>
                    <p>
                        As described in more detail below, our Site may use cookies to store your preferences, display content based upon what you view on our Site to
                        personalize your visit, analyze trends, administer the Site, track users’ movements around the Site, serve targeted advertising and gather demographic
                        information about our user base as a whole. On mobile websites and mobile apps, we may use Anonymous Device IDs and/or Advertiser Identifiers in a
                        manner similar to our use of cookies on our websites. To opt-out of advertising and certain other cookies, please see the opt-out links below.
                    </p>
                    <p></p>
                    <p>
                        <b>Savings Storm Cookies (First Party Cookies):</b> We, at Savings Storm, may use our cookies to improve your web-browsing experience. For example, we use a
                        cookie to reduce the time it takes for you to submit purchase requests by storing a registered user’s e-mail address so that the user does not need to
                        engaging Savings Storm services. Savings Storm cookies are associated with a user’s Personal Data. However, no third party may use the information we
                        collect through our Savings Storm cookies for their own purposes.
                    </p>
                    <p></p>
                    <p>
                        <b>Third Party Cookies:</b> We work with third-parties that place cookies on our Site to provide their services, including:
                    </p>
                    <ul className="PrivayTerms__list">
                        <li><b>Ad Targeting:</b> We work with third-party advertising companies to serve ads while you are visiting our Site and permit these companies to place and
                            access their own cookies on your computer in the course of serving advertisements on this Site. These companies may use information obtained
                            through their cookie (which does not include your name, address, e-mail address or telephone number) about your visits to our and other websites, in
                            combination with non-personally identifiable information about your purchases and interests from other online sites in order to provide
                            advertisements about goods and services of interest to you. Other companies’ use of their cookies is subject to their own privacy policies and not ours.
                        </li>
                        <li><b>Data Providers:</b> We also allow data providers to collect web log data from you (including IP address and information about your browser or operating
                            system), when you visit our Site, or place or recognize a unique cookie on your browser to enable you to receive customized ads or content. These
                            cookies contain no personally identifiable information. The cookies may reflect de-identified demographic or other data linked to data you voluntarily
                            have submitted to us, e.g., your email address, that we may share with data providers solely in hashed, non-human readable form.
                        </li>
                        <li>
                            <b>Analytics/Measurement:</b> We also use third-party analytics cookies to gain insight into how our visitors use the Site, to find out what works and what
                            does not, to optimize and improve our websites and to ensure we continue to be interesting and relevant. The data we gather includes which web
                            pages you have viewed, which referring/exit pages you have entered and left from, which platform type you have used, date and time stamp
                            information and details such as the number of clicks you make on a given page, your mouse movements and scrolling activity, the search words you
                            use and the text you type while using our Site. We also make use of analytics cookies as part of our online advertising campaigns to learn how users
                            interact with our website after they have been shown an online advertisement, which may include advertisements on third-party websites
                        </li>
                        <li>
                            <b>Remarketing Pixel Tags:</b> In addition, we may share web site usage information about visitors to our Sites with third-party advertising companies for
                            the purpose of managing and targeting advertisements and for market research analysis on our Site and other sites. For these purposes, we and our
                            third-party advertising companies may place pixel tags (also called clear gifs) on some of the pages you visit on our Site. We will then use the non-
                            personally identifiable information that is collected using these pixel tags to serve you relevant advertisements when you are visiting other sites on
                            the Internet. These advertisements may be targeted to specific searches you conducted on Savings Storm during earlier browsing sessions.
                        </li>
                        <li>
                            <b>Flash Cookies:</b> Our partners, who may provide certain features on our site or display advertising based on your browsing activity, also use Local
                            Shared Objects such as Flash cookies, and Local Storage such as HTML5, to collect and store content information and preferences. Various browsers
                            may offer their own management tools for removing HTML5 local storage. To manage Flash cookies, please  <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html" target="_blank">click here</a>.
                        </li>
                    </ul>
                    <p></p>
                    <p>
                        <b>Opting Out of Cookies:</b> We provide you with choice over the use of cookies as described in this Privacy Policy. However, our Site is not currently
                        configured to respond to Do Not Track signals. If you’d rather we didn’t use Savings Storm cookies when you visit us, check with your own browser’s
                        settings and “help” menu for how to configure your settings to reject cookies.
                    </p>
                    <p></p>
                    <p>
                        If you would like to opt-out of third-party cookies, here are some options:
                    </p>
                    <p></p>
                    <ul className="PrivayTerms__list">
                        <li>If you would like to opt-out of ad targeting cookies set by Google, please click here: <span style={{wordWrap: 'break-word'}}><a href="https://www.google.com/settings/u/0/ads?hl=en" target="_blank">https://www.google.com/settings/u/0/ads?hl=en</a> </span> </li>
                        <li>In order to control the collection of data for analytical purposes by Google Analytics, you may want to visit the following link: <span style={{wordWrap: 'break-word'}} ><a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a></span></li>
                    </ul>
                    <p></p>
                    <p>Mobile Devices</p>
                    <ul className="PrivayTerms__list">
                        <li><b>Anonymous Device Identifiers and Hashed IDs: </b> We and/or data providers that we have engaged may collect and store a unique identifier matched
                        to your mobile device, in order to deliver customized ads or content while you use applications or surf the internet, or to identify you in a unique
                        manner across other devices or browsers. In order to customize these ads or content, we or a data partner may collect de-identified demographic or
                        other data about you (received from third parties) either to data you voluntarily have submitted to us, e.g., your email address, or to data passively 
                        collected from you, such as your device identifier or IP address. However, we will share your email address solely in hashed, non-human readable
                        form.
                        </li>
                    </ul>
                    <p></p>
                    <p>
                    If you no longer wish to receive interest-based advertising on your mobile device browser or applications, please refer to your device’s operating system
                    settings, or follow instructions below.
                    </p>
                    <p></p>
                    <ul className="PrivayTerms__list">
                        <li>Android Users (version 2.3 and above): To use the “opt-out of interest-based advertising” option, follow the instructions provided by Google here:
                            <a href="https://support.google.com/googleplay/answer/3405269" target="_blank">Google Play Help</a>
                        </li>
                        <li>
                        iOS users (version 6 and above): To use the “Limit Ad-Tracking” option, follow the instructions provided by Apple here: <a href="https://support.apple.com/en-us/105131" target="_blank"> Apple Support Center</a>
                        </li>
                    </ul>
                    <p></p>
                    <p>
                    Please keep in mind that, without cookies, you may not have access to certain features on our Site, including access to your profile/account and certain
                    personalized content. Removing all cookies from your computer could also affect your subsequent visits to certain web sites, including the Savings Storm
                    web site, by requiring that, for example, you enter your login name when you return to that web site.
                    </p>
                    <p></p>
                    <p className="PrivacyTerms__title">7. How You Can Access or Change Your Personally-Identifying Information</p>
                    <p>
                    Savings Storm understands that you may want to change, access or delete your Personal Data. You may do so by accessing emailing us at <b>help@savingsstorms.com</b>  
                    &nbsp;or calling <b>(888) 474-3195.</b> To protect your privacy and security, we may need to verify your identity before granting access or 
                    making changes to your personally-identifying information. 
                    </p>
                    <p></p>
                    <p>
                    Any request to access your personally-identifying information will be answered in less than 30 days. If you have registered your profile on any of the
                    services available on our Site, your e-mail address and password are required in order to access your profile information. We will retain your information 
                    for as long as your account is active or as needed to provide you services. In addition, we will retain and use your information as necessary to comply 
                    with our legal obligations, resolve disputes, and enforce our agreements.
                    </p>
                    <p></p>
                    <p className="PrivacyTerms__title">8. Children’s Privacy</p>
                    <p>
                    Persons under the age of 18 are not eligible to use any services on our Site without direct parental supervision and we do not intentionally collect 
                    information from minors.
                    </p>
                    <p></p>
                    <p className="PrivacyTerms__title">9. How to Contact Savings Storm</p>
                    <p>
                    Should you have any questions, concerns or comments, please send an email to <b>help@savingsstorms.com</b> or by calling <b>(888) 474-3195</b> during normal 
                    business hours. Be aware that email is not guaranteed to be a secure means of communication.
                    </p>

                </div>
            </section>
            <section className="PrivacyTerms__customer-service-container">
                <div className="PrivacyTerms__customer-service">
                    <ContactCard />
                </div>
            </section>

        </>
    );
};

export default PrivacyTerms;